<!--
 * @Author: your name
 * @Date: 2022-04-01 10:22:33
 * @LastEditTime: 2022-06-07 15:03:25
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return{

    }
  },
  created(){
    // if(isAndroid()){
    //    if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    //            this.handleFontSize()
    //        } else {
    //            if (document.addEventListener) {
    //                document.addEventListener("WeixinJSBridgeReady", this.handleFontSize, false);
    //            } else if (document.attachEvent) {
    //                document.attachEvent("WeixinJSBridgeReady", this.handleFontSize);
    //                document.attachEvent("onWeixinJSBridgeReady", this.handleFontSize);  }
    //        }
    // }
  },
  methods:{
    // 如果是安卓手机，禁止在微信内更改字体大小
    handleFontSize(){
        // 设置网页字体为默认大小
         WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
        // 重写设置网页字体大小的事件
         WeixinJSBridge.on('menu:setfont', function() {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
        })
    }
  }
}
</script>
<style>
</style>
