/*
 * @Author: your name
 * @Date: 2022-04-01 10:22:33
 * @LastEditTime: 2022-06-07 16:31:07
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import isweixin from "@/untils/isweixin.js"
Vue.use(VueRouter)
//解决路由跳转报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect:"/guide"
  },
  // 引导页
  {
    path: '/guide',
    name: 'guide',
    component: () => import( '@/views/guide/index.vue'),
    meta:{
      title:"引导页"
    }
  },
  // 包含导航栏的框架页面
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/components/layout/index.vue'),
    children:[
    // 首页
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue'),
        meta:{
          title:"首页"
        }
      },
      {
        path:"/selectpack",
        name:"selectpack",
        component:() => import("@/views/selectpack/index.vue"),
        meta:{
          title:"套餐办理"
        }
      },
      // 我的页面
      {
       path: '/user',
        name: 'user',
       component: () => import('@/views/user/index.vue'),
        meta:{
        title:"我的"
        }
      }
      // wifi设置
      // {
      //   path: '/setwifi',
      //    name: 'setwifi',
      //   component: () => import('@/views/setwifi/index.vue'),
      //    meta:{
      //    title:"wifi设置"
      //    }
      // },
    ]
    
  },
  //  iframebox 售后服务
  {
    path:"/service",
    name:"service",
    component:() =>import("@/views/service/"),
    meta:{
      title:"锐wifi-客服中心"
    }
  },
  // 付款成功 buysuccess
  {
    path:"/buysuccess",
    name:"buysuccess",
    component:() =>import("@/views/buysuccess/"),
    meta:{
      title:"支付成功"
    }
  },
  // 如果不是在微信环境下的提示
  {
    path:"/ctoast",
    name:"ctoast",
    component:() => import("@/components/ctoast/"),
    meta:{
      title:"请用微信打开"
    }
  },
  // // 套餐办理
  // {
  //   path:"/selectpack",
  //   name:"selectpack",
  //   component:() => import("@/views/selectpack/index.vue"),
  //   meta:{
  //     title:"套餐办理"
  //   }
  // }
  // 订单页面
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/index.vue'),
    meta:{
      title:"订单详情"
    }
  },
  // 余额充值
  {
    path:"/recharge",
    name:"recharge",
    component:() => import("@/views/recharge/index.vue"),
    meta:{
      title:"余额充值"
    }
  },
  //  // 切换设备
  // {
  //   path:"/switchwifi",
  //   name:"switchwifi",
  //   component:() => import("@/views/switchwifi/index.vue"),
  //   meta:{
  //     title:"切换设备"
  //   }
  // },
  // 关于我们
  {
    path:"/aboutus",
    name:"aboutus",
    component:() => import("@/views/aboutus/index.vue"),
    meta:{
      title:"关于我们"
    }
  },
  // 意见反馈
  {
    path:"/feedback",
    name:"feedback",
    component:() => import("@/views/feedback/index.vue"),
    meta:{
      title:"意见反馈"
    }
  },
  // 账单明细
  {
    path:"/bill",
    name:"bill",
    component:() => import("@/views/bill/index.vue"),
    meta:{
      title:"余额明细"
    }
  },
  // 用户协议
  {
    path:"/termsService",
    name:"termsService",
    component:() => import("@/views/termsService/index.vue"),
    meta:{
      title:"用户协议"
    }
  },
  // 隐私政策
  {
    path:"/privacy",
    name:"privacy",
    component:() => import("@/views/privacy/index.vue"),
    meta:{
      title:"隐私政策"
    }
  },
  // 设置页面
  {
    path:"/setup",
    name:"setup",
    component:() => import("@/views/setup/index.vue"),
    meta:{
      title:"设置"
    }
  },
  // 用户资料
  {
    path:"/userdata",
    name:"userdata",
    component:() => import("@/views/userdata/index.vue"),
    meta:{
      title:"个人资料"
    }
  },
  {
    path:"/frontpage",
    name:"frontpage",
    component:() => import("@/views/frontpage/index.vue"),
    meta:{
      title:"测试获取"
    }
  },
  {
    path:"/download",
    name:"download",
    component:() => import("@/views/download/index.vue"),
    meta:{
      title:"格行上网"
    }
  },
  {
    path:"/selectpackCopy",
    name:"selectpackCopy",
    component:() => import("@/views/selectpackCopy/index.vue"),
    meta:{
      title:"套餐办理"
    }
  },
  {
    path:"/changeNetwork",
    name:"changeNetwork",
    component:() => import("@/views/changeNetwork/index.vue"),
    meta:{
      title:"网络配置"
    }
  },
  {
    path:"/coupon",
    name:"coupon",
    component:() => import("@/views/coupon/index.vue"),
    meta:{
      title:"优惠券"
    }
  },
  {
    path:"/invite",
    name:"invite",
    component:() => import("@/views/invite/index.vue"),
    meta:{
      title:"邀请"
    }
  },
  {
    path:"/staffInvite",
    name:"staffInvite",
    component:() => import("@/views/staffInvite/index.vue"),
    meta:{
      title:"内部邀请"
    }
  },
  {
    path:"/authentication",
    name:"authentication",
    component:() => import("@/views/authentication/index.vue"),
    meta:{
      title:"实名认证查询"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>{
  document.title = to.meta.title
  // 如果不是在微信浏览器中打开
  // if(!isweixin() && to.path !="/ctoast"){
  //   if(to.path == "/download"){
  //     next()
  //   }else{
  //     next({path:"/ctoast"})
  //   }
  // }
  // else{
  //   next()
  // }
  next()
})
export default router
