/*
 * @Author: your name
 * @Date: 2022-04-01 10:22:33
 * @LastEditTime: 2022-06-08 14:51:44
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/css/reset.css"
// 引入echarts
import * as echarts from "echarts";
import qs from "qs"
Vue.prototype.qs = qs
import isPhone from "@/untils/isPhone.js"
// 引入vconsole 方便手机端调试
// import Vconsole from 'vconsole'; 
// const vConsole = new Vconsole()
// Vue.use(vConsole) 
// 引入封装好的网络请求
import {getState,bindPhone,sendCode,getuserinfo,restart,getPackList,checkBalance,balancepay,getpayinfo,iccidInfo,getcardinfo,getorderList,getpayList,getCurOpenid,subFeedBack,officialState,getindexinfo,myCenter,iccidList,balanceLog,getPackageList,getRechargeList,getFeedTags,getWechatQRcode,setRealState,setAliasName,getUrlShare,getNetList} from "@/untils/api.js"
Vue.prototype.getState = getState
Vue.prototype.bindPhone = bindPhone
Vue.prototype.sendCode = sendCode
Vue.prototype.getuserinfo = getuserinfo
Vue.prototype.mainrestart = restart
Vue.prototype.getPackList = getPackList
Vue.prototype.checkBalance = checkBalance
Vue.prototype.balancepay = balancepay 
Vue.prototype.getpayinfo = getpayinfo
Vue.prototype.iccidInfo = iccidInfo
Vue.prototype.getcardinfo = getcardinfo
Vue.prototype.getorderList = getorderList
Vue.prototype.getpayList = getpayList
Vue.prototype.getCurOpenid = getCurOpenid
Vue.prototype.subFeedBack = subFeedBack
Vue.prototype.officialState = officialState
Vue.prototype.getindexinfo = getindexinfo
Vue.prototype.myCenter = myCenter
Vue.prototype.iccidList = iccidList
Vue.prototype.balanceLog = balanceLog
Vue.prototype.getPackageList = getPackageList
Vue.prototype.getRechargeList = getRechargeList
Vue.prototype.getFeedTags = getFeedTags
Vue.prototype.getWechatQRcode = getWechatQRcode
Vue.prototype.setRealState = setRealState
Vue.prototype.setAliasName = setAliasName
Vue.prototype.getUrlShare = getUrlShare
Vue.prototype.getNetList = getNetList
// 引入 flexible 用于设置 rem 基准值
// 导入测试接口
import {newget} from "./untils/request.js"
Vue.prototype.newget = newget
// 绑定echarts到vue原型上
Vue.prototype.$echarts = echarts
import 'lib-flexible/flexible.js'
import Vant from 'vant';
import 'vant/lib/index.css';
// 判断是不是ios
Vue.prototype.isPhone = isPhone
// 引入字体文件
import "@/assets/css/font.css"
Vue.use(Vant)
Vue.config.productionTip = false
import axios from 'axios'
Vue.prototype.$axios = axios
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
