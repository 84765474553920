/*
 * @Author: your name
 * @Date: 2022-02-09 16:42:00
 * @LastEditTime: 2022-06-07 14:50:35
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-shoping\src\router\isPhone.js
 */
export const isPhone = function (){
  var u = navigator.userAgent 
  // var app = navigator.appVersion;
  // var isXiaomi = u.indexOf('XiaoMi') > -1; // 小米手机
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; // 其它安卓
  var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios
  if (isIOS) {
    return true
  }else if(isAndroid) {
    return false
  }
}
export default isPhone
