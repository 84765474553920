/*
 * @Author: your name
 * @Date: 2022-03-03 13:41:36
 * @LastEditTime: 2022-06-08 14:38:02
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \wifivue\src\untils\api.js
 */
import {get,post} from "./request.js"
// 用ICCID换取当前卡的状态
export const  getState = async (params) =>{
  return await get({url:"getWifiState",data:params?params.data:{}})
}
// 获取官方实名状态
export const  officialState = async (params) =>{
  return await get({url:"getFlowInfo",data:params?params.data:{}})
}
// 绑定手机号
export const bindPhone = (params) =>{
  return post({url:"bindSlPhone",data:params?params.data:{}})
}
// 发送验证码
export const sendCode = (params) =>{
  return post({url:"getSlCode",data:params?params.data:{}})
}
// 用icciD获取当前用户的个人信息
export const  getuserinfo = (params) =>{
  return  get({url:"personCenter",data:params?params.data:{}})
}
// 获取当前首页的信息
export const  getindexinfo = (params) =>{
  return  get({url:"getIccidInfo",data:params?params.data:{}})
}
// 获取我的页面的信息
export const  myCenter = (params) =>{
  return get({url:"myCenter",data:params?params.data:{}})
}
// 设备重启(可获取部分数据即非索朗接口)
export const  restart = (params) =>{
  return get({url:"getinfo",data:params?params.data:{}})
}
// 获取当前页面的套餐列表
export const  getPackList = (params) =>{
  return get({url:"getPackList",data:params?params.data:{}})
}
// 检查账号余额是否充足 Wxpay/checkBalance
export const  checkBalance = (params) =>{
  return post({url:"checkBalance",data:params?params.data:{}})
}
// 余额支付 /Wxpay/apply
export const  balancepay = (params) =>{
  return post({url:"Wxpay/apply",data:params?params.data:{}})
} 
// 获取支付参数
export const  getpayinfo = (params) =>{
  return post({url:"newPayment",data:params?params.data:{}})
} 
// 获取当前要续费的套餐详情 news/iccidInfo
export const  iccidInfo = (params) =>{
  return get({url:"getNewState",data:params?params.data:{}})
}
// 根据ICCID和buy_id获取当前信息
export const getcardinfo =(params) =>{
  return get({url:"getPackInfo",data:params?params.data:{}})
}
// 套餐订购记录
export const getorderList = (params) =>{
  return get({url:"getOrderList",data:params?params.data:{}})
}
// 充值记录 
export const getpayList = (params) =>{
  return get({url:"user_pay_list",data:params?params.data:{}})
}
// 获取OPENID Wxapi/getCurOpenid
export const getCurOpenid = (params) =>{
  return post({url:"getWxOpenid",data:params?params.data:{}})
}
// 提交反馈 Wxapi/getCurOpenid
export const subFeedBack = (params) =>{
  return post({url:"newFeedBack",data:params?params.data:{}})
}
// 获取套餐列表 getPackageList
export const getPackageList = (params) =>{
  return get({url:"getPackageList",data:params?params.data:{}})
}
// 我的设备列表 news/getIccidList
export const iccidList = (params) =>{
  return get({url:"getIccidList",data:params?params.data:{}})
}
// 余额明细 getBalanceLog
export const balanceLog = (params) =>{
  return get({url:"getBalanceLog",data:params?params.data:{}})
}
// 充值列表 getRechargeList
export const getRechargeList = (params) =>{
  return get({url:"getRechargeList",data:params?params.data:{}})
}
// 反馈原因列表 getFeedTags
export const getFeedTags = (params) =>{
  return get({url:"getFeedTags",data:params?params.data:{}})
}
// 获取企业微信二维码 getWechatQRcode
export const getWechatQRcode = (params) =>{
  return get({url:"getWechatQRcode",data:params?params.data:{}})
}
// 如果硕郎实名认证状态消失 setRealState
export const setRealState = (params) =>{
  return post({url:"setRealState",data:params?params.data:{}})
}
// 给设备设置别名
export const setAliasName = (params) =>{
  return post({url:"setAliasName",data:params?params.data:{}})
}
// 非微信环境内获取跳转到小程序的链接
export const getUrlShare = (params) =>{
  return get({url:"getUrlScheme",data:params?params.data:{}}) 
}
// 切换卡 获取设备信息
export const getNetList = (params) =>{
  return get({url:"getNetList",data:params?params.data:{}})
}
// 优惠券列表
export const getCouponsList = (params) =>{
  return get({url:"getCouponsList",data:params?params.data:{}})
}
// 获取邀请页面数据
export const getInviteInfo = (params) => {
  return get({url:"getInviteInfo", data:params?params.data:{}})
}
// 优惠劵列表直接使用优惠劵
export const useCoupon = (params) => {
  return post({url:"useCoupon", data:params?params.data:{}})
}
// 客服邀请用户获取验证码
export const getInviteCode = (params) => {
  return post({url:"getInviteCode", data:params?params.data:{}})
}
// 客服邀请用户
export const addInvite = (params) => {
  return post({url:"addInvite", data:params?params.data:{}})
}
// 查询是否实名了
export const getRealStatus = (params) => {
  return get({url:"getRealStatus",data:params?params.data:{}})
}
export default{getState,bindPhone,sendCode,getuserinfo,getindexinfo,restart,getPackList,checkBalance,balancepay,getpayinfo,iccidInfo,getcardinfo,getorderList,getpayList,getCurOpenid,subFeedBack,officialState,myCenter,iccidList,balanceLog,getPackageList,getRechargeList,getFeedTags,getWechatQRcode,setRealState,setAliasName,getUrlShare,getNetList,getCouponsList,getInviteInfo,useCoupon,getInviteCode,addInvite,getRealStatus} 