/*
 * @Author: your name
 * @Date: 2022-03-01 15:10:32
 * @LastEditTime: 2022-03-01 15:26:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue-shoping\src\untils\isweixin.js
 */
export const isweixin = function (){
  var ua = navigator.userAgent.toLowerCase();
  if(ua.match(/MicroMessenger/i)=="micromessenger") {
      return true;
  } else {
      return false;
  }
}
export default isweixin